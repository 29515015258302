<template>
    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>
  </template>
  
  <script>
  import {
   BSpinner
  } from "bootstrap-vue";
  
  import { VueGoodTable } from "vue-good-table";
  import TableActions from "@/views/components/TableActions.vue";
  import CrudButtons from "@/views/components/CrudButtons.vue";
  // eslint-disable-next-line import/no-cycle
  // eslint-disable-next-line import/no-cycle
  import CardStatistic from "@/views/components/CardStatistic.vue";
  // eslint-disable-next-line import/no-cycle
  import useZaloAccountCallback from "./useZaloCallback";
  import ZaloAccountModal from "../modal/ZaloAccountModal.vue";
  import ZaloAccountDetailModal from "../detail/ZaloAccountDetailModal.vue";
  import ZaloAccountLoginModal from "../login/ZaloAccountLoginModal.vue";
  
  export default {
    components: {
      BSpinner
    },

    setup() {
      const {
        isLoading,
        linkOA
      } = useZaloAccountCallback();
  
      return {
        isLoading,
        linkOA
      };
    },
  
    created() {
        this.linkOA()
    },
  
  
  };
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
 
  